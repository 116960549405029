<template>
  <van-nav-bar :title="$route.meta.title" left-text="" left-arrow @click-left="$router.go(-1)" />
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {}
  }
}
</script>

<style></style>
