import request from '@/utils/request'

export function getCarts(id) {
  return request({
    url: '/wxcart/list',
    method: 'get',
    params: { id }
  })
}

export function getCartCount() {
  return request({
    url: '/wxcart/cartCount',
    method: 'get'
  })
}


export function addCart(data) {
  return request({
    url: '/wxcart/addcart',
    method: 'post',
    data
  })
}

export function updqty(data) {
  return request({
    url: '/wxcart/updqty',
    method: 'post',
    data
  })
}

export function updstatus(data) {
  return request({
    url: '/wxcart/updstatus',
    method: 'post',
    data
  })
}

export function delsku(data) {
  return request({
    url: '/wxcart/delsku',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/wxcart/del',
    method: 'post',
    data
  })
}
