import Vue from 'vue'
import {
  Tabbar,
  Button,
  TabbarItem,
  Swipe,
  SwipeItem,
  Search,
  Tab,
  Tabs,
  Icon,
  Popup,
  Field,
  Image as VanImage,
  RadioGroup,
  Radio,
  Checkbox,
  Stepper,
  NavBar,
  Cell,
  CellGroup,
  Toast,
  Uploader,
  Form,
  Calendar,
  Picker,
  Col,
  Row,
  Grid,
  GridItem,
  Cascader,
  DatetimePicker,
  AddressList,
  Rate,
  Empty,
  Tag
} from 'vant'
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Search)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Button)
Vue.use(VanImage)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Stepper)
Vue.use(NavBar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Toast)
Vue.use(Uploader)
Vue.use(Form)
Vue.use(Calendar)
Vue.use(Picker)
Vue.use(Col)
Vue.use(Row)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Cascader)
Vue.use(AddressList)
Vue.use(DatetimePicker)
Vue.use(Rate)
Vue.use(Empty)
Vue.use(Tag)

// 全局注册快捷指令
Vue.prototype.Toast = Toast

