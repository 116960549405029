<template>
  <van-nav-bar left-text="" right-text="搜索" left-arrow @click-left="$router.go(-1)" @click-right="onClickRight">
    <template #title>
      <van-search
        v-model="value"
        shape="round"
        left-icon="search"
        right-icon=""
        background="transparent"
        :placeholder="placeholder"
        @clear="onClear"
      />
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入搜索内容'
    }
  },
  data() {
    return {
      value: ''
    }
  },
  methods:{
    onClickRight() {
      this.$emit('search', this.value)
    },
    onClear() {
      this.$emit('search', '')
    },
  }
}
</script>

<style></style>
