<template>
  <van-tabbar v-model="active" route fixed active-color="#F64C11">
    <van-tabbar-item to="/home">
      <span>首页</span>
      <template #icon>
        <img :src="active == 0 ? icon.homeActive : icon.home" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item :badge="cartCount > 0 ? cartCount : ''" to="/cart">
      <span>购物车</span>
      <template #icon>
        <img :src="active == 1 ? icon.cartActive : icon.cart" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/feedback">
      <span>反馈</span>
      <template #icon>
        <img :src="active == 2 ? icon.feedbackActive : icon.feedback" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/user">
      <span>我的</span>
      <template #icon>
        <img :src="active == 3 ? icon.userActive : icon.user" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
import { getCartCount } from '@/api/cart.js'
import { EventBus } from '@/store/event-bus.js'
export default {
  name: 'TabBar',
  data() {
    return {
      active: localStorage.getItem('active') || 0,
      icon: {
        home: require('@/assets/icon/home.png'),
        homeActive: require('@/assets/icon/home-active.png'),
        cart: require('@/assets/icon/cart.png'),
        cartActive: require('@/assets/icon/cart-active.png'),
        feedback: require('@/assets/icon/feedback.png'),
        feedbackActive: require('@/assets/icon/feedback-active.png'),
        user: require('@/assets/icon/user.png'),
        userActive: require('@/assets/icon/user-active.png')
      },
      cartCount: 0,
    }
  },

  mounted() {
    getCartCount().then((res) => {
      this.cartCount =  res.data
    })

    EventBus.$on('refreshCart', (data) => {
      console.log(data)
      this.cartCount = data
    });
  },
  // 保证以任何形式回到tabbar页面,下方保持高亮
  watch: {
    '$route.name'(val) {
      if (val === 'Home') {
        localStorage.setItem('active', 0)
        this.active = localStorage.getItem('active')
      }
      if (val === 'Cart') {
        localStorage.setItem('active', 1)
        this.active = localStorage.getItem('active')
      }
      if (val === 'FeedBack') {
        localStorage.setItem('active', 2)
        this.active = localStorage.getItem('active')
      }
      if (val === 'User') {
        localStorage.setItem('active', 3)
        this.active = localStorage.getItem('active')
      }
      console.log('watch', val)
    }
  }
}
</script>
<style lang="less" scoped>
img {
  width: auto;
}
</style>
