import Vue from 'vue'
import VueRouter from 'vue-router'
import { setToken,getToken,removeToken } from '@/utils/auth'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/pages/auth/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/home/index.vue'),
    meta: {
      title: '首页',
      showTabbar: true
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/pages/cart/index.vue'),
    meta: {
      title: '购物车',
      showTabbar: true
    }
  },
  {
    path: '/feedback',
    name: 'FeedBack',
    component: () => import('@/pages/feedback/index.vue'),
    meta: {
      title: '反馈',
      showTabbar: true
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/pages/user/index.vue'),
    meta: {
      title: '个人中心',
      showTabbar: true
    }
  },
  {
    path: '/user/profile',
    name: 'userProfile',
    component: () => import('@/pages/user/profile.vue'),
    meta: {
      title: '个人信息',
      showTabbar: false
    }
  },
  {
    path: '/user/address',
    name: 'UserAddress',
    component: () => import('@/pages/user/address.vue'),
    meta: {
      title: '我的地址',
      showTabbar: true
    }
  },
  {
    path: '/user/addressEdit',
    name: 'addressEdit',
    component: () => import('@/pages/user/addressEdit.vue'),
    meta: {
      title: '新增地址',
      showTabbar: true
    }
  },
  {
    path: '/goods/list',
    name: 'GoodList',
    component: () => import('@/pages/good/list.vue'),
    meta: {
      title: '所有商品'
    }
  },
  {
    path: '/goods/detail/:id?',
    name: 'GoodDetail',
    component: () => import('@/pages/good/detail.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/order/submit',
    name: 'OrderSubmit',
    component: () => import('@/pages/order/submit.vue'),
    meta: {
      title: '提交订单'
    }
  },
  {
    path: '/order/list',
    name: 'OrderList',
    component: () => import('@/pages/order/list.vue'),
    meta: {
      title: '订单列表'
    }
  },
  {
    path: '/order/detail',
    name: 'OrderDetail',
    component: () => import('@/pages/order/detail.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/shop/list',
    name: 'ShopList',
    component: () => import('@/pages/shop/list.vue'),
    meta: {
      title: '店铺搜索'
    }
  },
  {
    path: '/shop/map',
    name: 'shopMap',
    component: () => import('@/pages/shop/shopMap.vue'),
    meta: {
      title: '店铺分布'
    }
  },
  {
    path: '/shop/bind',
    name: 'ShopBind',
    component: () => import('@/pages/shop/bind.vue'),
    meta: {
      title: '绑定店铺'
    }
  },
  {
    path: '/shop/dtl',
    name: 'ShopDtl',
    component: () => import('@/pages/shop/shopDtl.vue'),
    meta: {
      title: '店铺详情'
    }
  },
  {
    path: '/shop/edit',
    name: 'ShoprEdit',
    component: () => import('@/pages/shop/edit.vue'),
    meta: {
      title: '店铺信息'
    }
  },
  {
    path: '/shop/qrcode',
    name: 'Qrcode',
    component: () => import('@/pages/shop/qrcode.vue'),
    meta: {
      title: '店铺二维码',
      showTabbar: false
    }
  },{
    path: '/shop/visit',
    name: 'Visit',
    component: () => import('@/pages/shop/visit.vue'),
    meta: {
      title: '店铺打卡',
      showTabbar: false
    }
  },
  {
    path: '/feedback/form/zero',
    name: 'FeedbackFormZero',
    component: () => import('@/pages/feedback/form-zero.vue'),
    meta: {
      title: '反馈记录填写'
    }
  },
  {
    path: '/feedback/form/first',
    name: 'FeedbackFormFirst',
    component: () => import('@/pages/feedback/form-first.vue'),
    meta: {
      title: '反馈记录填写'
    }
  },
  {
    path: '/feedback/form/second',
    name: 'FeedbackFormSecond',
    component: () => import('@/pages/feedback/form-second.vue'),
    meta: {
      title: '反馈记录填写'
    }
  },
  {
    path: '/feedback/form/third',
    name: 'FeedbackFormThird',
    component: () => import('@/pages/feedback/form-third.vue'),
    meta: {
      title: '反馈记录填写'
    }
  },
  {
    path: '/agent',
    name: 'Agent',
    component: () => import('@/pages/agent/index.vue'),
    meta: {
      title: '温州安特业务专员',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: '/agent/profile',
    name: 'Profile',
    component: () => import('@/pages/agent/profile.vue'),
    meta: {
      title: '个人信息',
      showTabbar: false,
      roles: ['agent']
    }
  },
  
  {
    path: '/agent/goods',
    name: 'goodsList',
    component: () => import('@/pages/agent/goodsList.vue'),
    meta: {
      title: '产品目录',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: '/agent/goodsDtl/:id?',
    name: 'GoodsDtl',
    component: () => import('@/pages/agent/goodsDtl.vue'),
    meta: {
      title: '产品详情'
    }
  },
  {
    path: '/agent/agentOrder',
    name: 'Agentorder',
    component: () => import('@/pages/order/agentOrder.vue'),
    meta: {
      title: '订单列表',
      showTabbar: false,
      roles: ['agent']
    }
  },{
    path: '/agent/agentOrderDtl',
    name: 'AgentOrderDtl',
    component: () => import('@/pages/order/agentOrderDtl.vue'),
    meta: {
      title: '订单详情',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: '/agent/plan',
    name: 'PlanList',
    component: () => import('@/pages/agent/planList.vue'),
    meta: {
      title: '回访计划',
      roles: ['agent']
    }
  },
  {
    path: '/agent/planEdit',
    name: 'planEdit',
    component: () => import('@/pages/agent/planEdit.vue'),
    meta: {
      title: '新增回访计划',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: '/agent/record',
    name: 'RecordList',
    component: () => import('@/pages/agent/recordList.vue'),
    meta: {
      title: '回访登记',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: '/agent/recordEdit',
    name: 'RecordEdit',
    component: () => import('@/pages/agent/recordEdit.vue'),
    meta: {
      title: '回访登记',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: '/surveys',
    name: 'SurveyList',
    component: () => import('@/pages/agent/surveyList.vue'),
    meta: {
      title: '调查问卷',
      showTabbar: false
    }
  },
  {
    path: '/answers',
    name: 'answerList',
    component: () => import('@/pages/agent/answerList.vue'),
    meta: {
      title: '反馈记录',
      showTabbar: false
    }
  },
  {
    path: '/agent/bind',
    name: 'AgentBind',
    component: () => import('@/pages/agent/bind.vue'),
    meta: {
      title: '业务员绑定',
      showTabbar: false,
      roles: ['agent']
    }
  },
  {
    path: 'agent/commission',
    name: 'Commission',
    component: () => import('@/pages/agent/commission.vue'),
    meta: {
      title: '提成信息',
      showTabbar: false,
      roles: ['agent']
    }
  },

  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/auth/error.vue'),
    meta: {
      title: '抱歉，出错了',
      showTabbar: false
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { x: 0, y: 0 }
  }
})

// 全局路由守卫 设置网站标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '美容美发'
  console.log(process.env.NODE_ENV)
  const ua = navigator.userAgent.toLowerCase()
  console.log(ua)
  if (process.env.NODE_ENV === 'development') {
    // 开发环境，随便访问
    setToken('ZiiEsePx3KKp25IactguNA==')
    next()
  } else {
    // 生产环境，必须微信浏览器
    if (to.path === '/error') {
      removeToken()
      return next()
    }
   
    if (ua.match(/MicroMessenger/i) != 'micromessenger') {
      next('/error')
    } else {
      const token = getToken()
      if (to.path === '/auth') {
        removeToken()
        next()
      } else if (token === null ||  token === undefined) {
        // 去微信授权页面
        localStorage.setItem('now_url', to.fullPath) //当前页url与参数放入缓存
        next('/auth')
      } else {
        // 已授权，正常访问
        next()
      }
    }
  }
})

export default router
