import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cartCount: 3, // 初始化购物车数量
  },
  getters: {
    cartCount: state => state.cartCount,
  },
  mutations: {
    updCart(state, count) {
      state.cartCount = count;
    }
  },
  actions: {},
  modules: {},
});
