<template>
  <div id="app">
    <router-view />
    <!-- tab栏 -->
    <TabBar v-show="$route.meta.showTabbar" />
  </div>
</template>

<script>
import TabBar from './components/TabBar/index.vue'
export default {
  name: 'App',
  components: { TabBar },
  data() {
    return {
      defaultHeight: 0, //默认屏幕高度
      nowHeight: 0, //实时屏幕高度
      hideTabbar: false // 是否隐藏底部tabbar
    }
  },
  // window.onresize 监听屏幕变化处理软键盘弹起tabbar的问题
  mounted() {
    this.defaultHeight = window.innerHeight
    window.onresize = () => {
      this.nowHeight = window.outerHeight
    }
  },
  watch: {
    nowHeight() {
      console.log(1)
      this.defaultHeight !== this.nowHeight ? (this.hideTabbar = true) : (this.hideTabbar = false)
    }
  }
}
</script>
<style lang="less">
html,
body {
  color: #54585b;
  background-color: #f4f5f6;
  -webkit-user-select: none; /* Safari/Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none;
}
</style>
