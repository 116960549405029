import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入样式文件
import '@/assets/css/reset.css'
import '@/assets/css/index.less'

// tailwindcss  快速书写样式 文档地址 https://tailwind.nodejs.cn/docs/flex-basis
import 'tailwindcss/tailwind.css'

// 引入vant组件
import './vant/index'
// rem适配
import 'amfe-flexible/index.js'

// 全局组件挂载
import NavBar from '@/components/NavBar/NavBar.vue'
import NavBarSearch from '@/components/NavBar/NavBarSearch.vue'
Vue.component('NavBar', NavBar)
Vue.component('NavBarSearch', NavBarSearch)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
